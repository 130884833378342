/**
 * Хелпер генерации текста для счетчика слайда.
 * 
 * @param {Number} index  Индекс слайда
 * @param {Number} length Количество слайдов
 * 
 * @return {String}
 *
 * @author Зайцева Анастасия <Zayceva.AV1@dns-shop.ru>
 */
export const generateCountText = (index: number, length: number): string => {
	return `Слайд ${index + 1} из ${length};`;
}