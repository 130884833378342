import Flicking, {EVENTS} from '@egjs/flicking';
import {Arrow} from '@egjs/flicking-plugins';
import type {WillChangeEvent} from '@egjs/flicking/declaration/type/event';
import type {PropType} from 'vue';

export default {
	name:  'flicking-slider',
	props: {
		/** Имя слайдера */
		sliderName: {type: String, default: ''},
		/** Нужно ли слайдеру стрелки для управления */
		isNeedArrow: {type: Boolean, default: true},
		/** Список слайдов, принимаемый из род. компонента */
		slides: {type: Object as () => PropType<NodeListOf<HTMLElement>>, default: null},
	},
	setup(props) {

		/** Ссылка на объект слайдера */
		let slider: (Flicking | null) = null;

		/** Индекс активного слайда */
		const sliderIndex = ref(0);

		/** Объект с состояниями стрелок переключения слайдера */
		const sliderArrowsState = ref({prev: true, next: false});

		/** @inheritDoc */
		onMounted(() => {
			slider = new Flicking(`.${props.sliderName}`, {
				align: 'prev',
				bound: true,
			})
				.addPlugins(new Arrow());

			slider.on(EVENTS.MOVE, reachSlides);

			slider.on(EVENTS.WILL_CHANGE, (event: WillChangeEvent) => {
				sliderIndex.value = event.index;
			});

			setTimeout(() => {
				activateSlide(0, slider.camera.visiblePanels.length);
			});
		});

		/** Обработчик события движения слайдера. */
		const reachSlides = () => {
			const position: number = slider.holding ? slider.camera.position : slider.control.controller.animatingContext.end;

			updateState(position);
		};

		/**
		 * Обновление состояний кнопок слайдера.
		 *
		 * @param position Текущая позиция слайдера
		 */
		const updateState = (position: number) => {
			sliderArrowsState.value.prev = position <= slider.camera.range.min;
			sliderArrowsState.value.next = position >= slider.camera.range.max;
		}

		/** Установка активного слайда, по которому возможна табуляция */
		watch(() => sliderIndex.value, (value) => {
			activateSlide(value, slider.camera.visiblePanels.length);
		});

		/**
		 * Активация необходимого слайда и скрытие остальных.
		 *
		 * @param activeIndex   Индекс активного слайда
		 * @param visibleSlides Количество видимых на странице слайдов
		 */
		const activateSlide = (activeIndex: number, visibleSlides: number) => {
			props.slides.forEach((slide: HTMLElement, index: number) => {
				slide.setAttribute('aria-hidden', 'true');
				slide.setAttribute('tabindex', '-1');

				for (let i = activeIndex; i < activeIndex + visibleSlides; i++) {
					if (i === index) {
						slide.setAttribute('aria-hidden', 'false');
						slide.setAttribute('tabindex', '0');
					}
				}
			})
		};

		return {
			sliderArrowsState,
		}
	}
}
