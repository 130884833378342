<script lang="ts" src="./index.ts"></script>

<template>
	<div class="slider flicking-viewport" :class="sliderName" :slides="slides">
		<div class="flicking-camera">
			<slot></slot>
		</div>
		<div
			class="slider-arrow-container"
			v-if="isNeedArrow"
		>
			<button
				:class="[
					'flicking-arrow-prev',
					'slider-arrow-container__arrow',
					'slider-arrow-container__arrow_prev',
					{'slider-arrow-container__arrow_disable': sliderArrowsState.prev}
				]"
				aria-label="Предыдущий слайд"
			>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
				     xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd"
					      d="M15.7455 5.03968C16.0384 5.33257 16.0384 5.80744 15.7455 6.10034L9.84582 12L15.7455 17.8997C16.0384 18.1926 16.0384 18.6674 15.7455 18.9603C15.4526 19.2532 14.9777 19.2532 14.6848 18.9603L8.25483 12.5303C8.11417 12.3897 8.03516 12.1989 8.03516 12C8.03516 11.8011 8.11417 11.6103 8.25483 11.4697L14.6848 5.03968C14.9777 4.74678 15.4526 4.74678 15.7455 5.03968Z"
					      fill="#8C8C8C"
					/>
				</svg>
			</button>
			<button
				:class="[
					'flicking-arrow-next',
					'slider-arrow-container__arrow',
					'slider-arrow-container__arrow_next',
					{'slider-arrow-container__arrow_disable': sliderArrowsState.next}
				]"
				aria-label="Следующий слайд"
			>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
				     xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd"
					      d="M8.97358 5.03968C9.26647 4.74678 9.74134 4.74678 10.0342 5.03968L16.4642 11.4697C16.6049 11.6103 16.6839 11.8011 16.6839 12C16.6839 12.1989 16.6049 12.3897 16.4642 12.5303L10.0342 18.9603C9.74134 19.2532 9.26647 19.2532 8.97358 18.9603C8.68068 18.6674 8.68068 18.1926 8.97358 17.8997L14.8732 12L8.97358 6.10034C8.68068 5.80744 8.68068 5.33257 8.97358 5.03968Z"
					      fill="#8C8C8C"
					/>
				</svg>
			</button>
		</div>
	</div>
</template>

<style lang="scss">
@import 'assets/styles/variables/_respond-to.scss';

.slider {
	cursor: grab;

	&:active {
		cursor: grabbing;
	}

	@media (min-width: $container-desktop) {
		&:hover, &:active {
			.slider-arrow-container {
				opacity: 1;
			}
		}
	}
}

.slider-arrow-container {
	opacity: 0;

	&__arrow {
		z-index:         2;
		display:         flex;
		justify-content: center;
		align-items:     center;
		position:        absolute;
		top:             calc(50% - 24px);
		width:           48px;
		height:          48px;
		background:      #fff;
		box-shadow:      0 4px 10px rgba(0, 0, 0, .1);
		border-radius:   30px;
		border:          none;
		cursor:          pointer;

		&:not(&_disable) {
			&:hover {
				width:      52px;
				height:     52px;
				box-shadow: 0 4px 20px rgba(0, 0, 0, .1);

				svg path {
					fill: #4e4e4e;
				}
			}
		}

		&_disable {
			display: none;
		}

		&_prev {
			left: 10px;
		}

		&_next {
			right: 30px;
		}
	}
}
</style>
